////////////////
// Colors
////////////////

// -- Definitions
$tiicker-green: #85bb65;
$green-highlight: #8cce65;
$navy: #091c37;
$pale-mint: #f0f7f5;
$grey: #575a60;
$light-grey: rgba(87, 90, 96, 0.2);
$faded-navy: rgba(9, 28, 55, 0.1);
$error: #d73740;
$disconnect-error: #cf5520;

// -- General
$main: $tiicker-green;
$secondary: $navy;
$highlight: $green-highlight;
$background: $pale-mint;

// -- Fonts
$header: $navy;
$body: $grey;

////////////////
// Breakpoints
////////////////

$xsmall: 576px;
$small: 768px;
$medium: 992px;
$large: 1200px;
$xlarge: 1400px;

//Sidebar
$ad-sidebar-max-width: 300px;
