@import "../../../styles/theme.scss";

.container {
  background: $pale-mint;
  background: linear-gradient(
    0deg,
    rgba(240, 247, 245, 0) 0%,
    rgba(240, 247, 245, 1) 25%
  );
}
