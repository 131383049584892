@import "styles/theme.scss";

.SocialLink {
  font-size: 30px;
  transition: color 0.2s;
  color: $navy;

  &:not(:last-child) {
    margin-right: 15px;
  }
  &:hover {
    color: $main;
  }

  &_Inverse {
    color: #fefefe;
  }
}
