.modal {
  padding: 32px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.title {
  margin-bottom: 32px;
}

.description {
  margin-bottom: 56px;
}

.signIn {
  text-align: center;
  margin-bottom: 0;
}
