.textArea {
  width: 100%;
  padding: 11.25px 24px;
  border: solid 1px #575a60;
  background-color: transparent;
  transition: border-color 0.3s;
  font-size: 18px;
  color: grey;
  line-height: 1.75;
  outline: none;
}
