@import "../../../styles/theme.scss";

.StartEarningPerks {
  width: 100%;
  background-color: #f0f7f5;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 64px 34px;
  }

  &__header {
    max-width: 307px;
    text-align: center;
  }

  &__subHeader {
    font-size: 18px;
    line-height: 1.75;
    text-align: center;
    color: #575a60;
    margin: 0;
  }

  &__headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $medium) {
      margin-bottom: 32px;
    }
  }

  @media screen and (min-width: $medium) {
    &__content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 104px 0;
    }

    &__headerContainer {
      align-items: flex-start;
      max-width: 540px;
    }

    &__header,
    &__subHeader {
      text-align: left;
      max-width: max-content;
    }
  }
}
