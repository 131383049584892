@import "../../styles/theme.scss";

.modalParent {
  position: fixed;
  display: flex;
  justify-content: center;
  height: 100%;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media (max-height: 600px) {
    top: 20px;
  }
}

.modal {
  position: relative;
  height: min-content;
  max-height: calc(100vh - 200px);
  overflow: auto;
  background-color: white;
  z-index: 5;
  overscroll-behavior: contain;

  animation-duration: 0.3s;
  animation-name: fadein;

  @media (max-width: $small) {
    margin: 0 20px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
}

.closing {
  animation-duration: 0.3s;
  animation-name: fadeout;
}

@keyframes fadeout {
  to {
    opacity: 0;
  }
}

.closeButton {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 500;
}

.fullWidthSmall {
  @media (max-width: $small) {
    top: 0;
    bottom: 0;
  }
}

.fullWidth {
  @media (max-width: $small) {
    min-width: 100vw;
    min-height: 100vh;
    padding-top: 40px;
  }
}
