// @import "../../styles/typography.scss";
@import "../../styles/theme.scss";

.Footer {
  background-color: $navy;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 56px 20px 20px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__columnContainer,
  &__secondaryBlocksContainer {
    display: flex;
    flex-direction: column;
  }

  &__secondaryBlocksContainer {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    grid-gap: 200px;
    padding: 54px 0;
  }

  &__awardLogos {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 1em;
  }

  &__awardLogo {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__linkList {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(6, auto);
    grid-auto-flow: column;
    text-align: left;
    grid-gap: 25px;

    width: 100%;
    &__twoCol {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__link {
    color: white;
    white-space: nowrap;

    &:hover {
      color: $main;
    }
  }

  &__logo {
    height: 28px;
    width: 130px;
    margin-bottom: 56px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__formInputGroup {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  &__formEmailInput {
    height: 100%;
    border-right: none !important;
    border: 1px solid rgba(255, 255, 255, 0.3);
    min-height: 48px;
  }

  label {
    color: $green-highlight;
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 2.24px;
    text-align: left;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  &__menuHeader {
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 2.24px;
    text-align: left;
    color: $main;
    text-transform: uppercase;
    margin-bottom: 24px;
    color: $green-highlight;
  }

  &__menuBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    width: 100%;
    list-style: none;
    text-align: left;
  }

  &__menuBlockPerks {
    display: grid;
    grid-gap: 24px;
    width: 100%;
    list-style: none;
    text-align: left;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  &__menuLink {
    margin: 0;
    white-space: nowrap;
    a {
      color: #fff;
      text-decoration: none;
    }
    a:hover {
      color: $green-highlight;
    }
  }

  &__formErrorMessage {
    color: #ff4d4f;
    margin: 10px 0;
  }
  input:-webkit-autofill {
    box-shadow: none;
    -webkit-box-shadow: 0 0 0 50px $navy inset; /* Change the color to your own background color */
    -webkit-text-fill-color: #fff;
  }

  input:-webkit-autofill:focus {
    box-shadow: none;
    -webkit-box-shadow: 0 0 0 50px $navy inset; /*your box-shadow*/
    -webkit-text-fill-color: #fff;
  }

  input:focus,
  input.form-control:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  &__subscribeButton {
  }

  &__socialLinks {
    display: flex;
  }

  &__socialLinks > a {
    background-color: $navy;
    padding: 0;
    margin-right: 24px !important;

    &:hover {
      background-color: transparent;
    }
  }

  &__copyrightNotice {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
    p,
    a {
      margin: 0;
      font-size: 11px;
      text-align: left;
      color: #747474;
      margin-right: 3px;
    }

    a:hover {
      color: $green-highlight;
    }
  }

  &__socialAndCopy {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media screen and (min-width: $small) {
    &__secondaryBlocksContainer {
      grid-template-columns: 1fr 1fr;
    }
  }



  // Breakpoint styles
  @media screen and (min-width: $medium) {
    padding: 40px 0;

    .container {
      width: initial;
    }

    &__socialAndCopy {
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }

    &__secondaryBlocksContainer {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &__copyrightNotice {
      margin-top: 0;
      align-items: baseline;
    }

    &__twoCol {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 72px 0 32px;
    &__menuBlock,
    &__menuBlockPerks {
      grid-row-gap: 24px;
      grid-column-gap: 72px;
    }

    &__form {
      width: 350px;
    }

    &__columnContainer {
      flex-direction: row;
      padding-bottom: 54px;
    }

    &__secondaryBlocksContainer {
      margin-left: 150px;
      padding: 0;
    }

    &__linkList {
      grid-gap: 25px 35px;
    }
  }
  
  @media screen and (max-width: 767px) {
    &__linkList {
      grid-template-rows: repeat(3, auto);
      grid-template-columns: repeat(2, 1fr);
      padding: 0;
    }
    &__secondaryBlocksContainer {
      grid-gap: 50px;
    }
  }
}


.contactUsModal {
  padding: 32px;
}

.socialIcon {
  font-size: 24px;
}
