@import "styles/theme.scss";

.flexContainer {
  display: flex;
  align-items: center;
}

.flexCol {
  flex-direction: column;
}

.header {
  font-size: 60px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.6px;
  color: $navy;
  margin-top: 50px;
  text-align: center;
}

.description {
  margin-top: 24px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: $grey;
  max-width: 800px;
}

.buttonWrapper {
  height: 46px;
  margin-top: 40px;
  width: 335px;
}

.button {
  height: 46px;
  margin-left: 24px;
}

.featureSection {
  h2 {
    margin-top: 73px;
  }
}

.featureParent {
  column-gap: 108px;
  @media (max-width: $medium) {
    flex-direction: column;
  }
}

.feature {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  img,
  h5,
  p {
    align-self: center;
    text-align: center;
  }
  img {
    margin-top: 48px;
  }
  h5 {
    margin-top: 24px;
  }
  p {
    margin-top: 16px;
  }
}

.downloadsAndSupport {
  display: flex;
  margin: 136px 0px 120px 0px;
  @media (max-width: $medium) {
    flex-direction: column;
  }
}

.downloads {
  flex-grow: 2;
  background-color: $pale-mint;
  padding: 90px;
  text-align: center;
}

.support {
  @media (max-width: $medium) {
    margin-top: 36px;
  }
  flex-grow: 1;
  align-self: center;
  text-align: center;
  p {
    max-width: 70%;
  }
}

.supportModal {
  padding: 32px;
}
