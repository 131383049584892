@import "../../styles/theme.scss";

.AppCarousel {
  margin-top: 100px;

  &__imageWrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  &__image {
    margin-top: 10px;
    width: 240px;
    height: 530px;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  &__frame {
    position: absolute;
    margin-top: -5px;
    width: 288px;
    height: 555px;
  }
}
