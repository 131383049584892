@import "../../../styles/theme.scss";

.notification {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 500;
  transform: translate(-50%, 0);

  @media (max-height: 600px) {
    top: 20px;
  }
}

.message {
  animation-name: fadeIn;
  animation-duration: 0.3s;
  padding: 8px 12px;
  background: #fff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
  max-width: 600px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 12px;

  @media (max-width: $small) {
    max-width: 90%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.closing {
  animation-duration: 0.3s;
  animation-name: slideout;
}

.checkmark {
  min-width: 11px;
  height: 11px;
  color: $tiicker-green;
}

.questionMark {
  color: black;
  min-width: 12px;
  height: 12px;
}

.warning {
  min-width: 22px;
  min-height: 22px;
  color: $error;
}

@keyframes slideout {
  to {
    opacity: 0;
  }
}

.messageText {
  margin-bottom: 0;
  font-size: 14px;
}

.success {
  border: 1px solid $tiicker-green;
  border-radius: 12px;
}

.error {
  border: 1px solid $error;
  border-radius: 12px;
}

.info {
  border: 1px solid black;
  border-radius: 12px;
}
