.container {
  margin-top: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.button {
  margin-top: 24px;
  width: fit-content;
  span {
    font-weight: 700;
  }
}
