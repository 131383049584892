@import "../../styles/theme.scss";

.sliderContainer {
  width: 100%;
}

.sliderContainer_Integrated {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 42px;

  .swiper {
    width: 90% !important;
    padding: 16px !important;
  }

  @media screen and (max-width: $medium) {
    margin-bottom: 0;

    .swiper {
      width: 190% !important;
      padding: 0 !important;
    }
  }
}

.sliderContainer_Partner {
  & > div {
    div {
      display: flex;
      align-items: center;
    }
  }
}

.arrows {
  display: flex;
  justify-content: flex-end;

  &__prev,
  &__next {
    font-size: 40px;
    cursor: pointer;
    color: $tiicker-green;
  }

  &__next {
    margin-left: 16px;
  }

  &__disabled {
    color: $grey;
    opacity: 0.5;
    cursor: default;
  }

  svg {
    @media (max-width: $small) {
      scale: 0.8;
    }
  }
}

.bullets {
  color: black;
}

.pageContainer {
  position: static;
  display: flex;
  justify-content: left;
}

.pageContainer_Integrated {
  position: static;
  display: flex;
  justify-content: center;
}

.page {
  display: inline-block;
  border-top: 4px solid $tiicker-green;
  width: 47px;
  opacity: 0.2;
  margin-right: 16px;
  cursor: pointer;

  @media (max-width: $small) {
    display: none;
  }

  &__current {
    opacity: 1;
  }
}

.page_Partner {
  display: inline-block;
  border-top: 4px solid $tiicker-green;
  width: 47px;
  opacity: 0.2;
  margin-right: 5px;
  cursor: pointer;

  &__current {
    opacity: 1;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.buttonContainer_Partner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.integrated__arrow {
  @media screen and (max-width: $medium) {
    display: none;
  }
}

.integrated__navigation {
  display: flex;
  justify-content: center;

  @media screen and (max-width: $medium) {
    display: none;
  }
}

.integrated__mobileNavigation {
  padding: 0 24px;
  justify-content: center;

  @media screen and (min-width: $medium) {
    display: none;
  }
}

.page {
  border-top: 4px solid $tiicker-green;
  width: 47px;
  opacity: 0.2;
  margin-right: 16px;
  cursor: pointer;

  &__current {
    opacity: 1;
  }

  @media (max-width: $medium) {
    width: 20px;
  }
}
